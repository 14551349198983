import React from "react"

import { graphql, navigate } from "gatsby"
import moment from "moment"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./blogPost.css"

const content =
  "TAG Finance and Loans Pty Ltd ACN 609 906 863 Credit Representative Number 483873 have access to a panel of lenders via National Mortgage Brokers Pty Ltd ACN 093 874 376 / Australian Credit Licence 391209, which is a fully-owned subsidiary of Liberty Financial Pty Ltd ACN 077 248 983 / Australian Credit Licence 286596. Our brokers have access to products including those from Liberty Financial."

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      date
      categories {
        nodes {
          name
        }
      }
      author {
        node {
          name
        }
      }
    }
  }
`
const BlogPost = ({
  data: {
    wpPost: {
      title,
      content: blogPost,
      date,
      categories: {
        nodes: {
          "0": {
            name: category
          },
        },
      },
      author: {
        node: { name: author },
      }
    },
  },
  pageContext: { next, previous, nextTitle, previousTitle },
}) => {
  return (
    <Layout subfooter={content}>
      <h3 className="blog-post--header text-primary">{title}</h3>
      <SEO title="Blog" />
      <div>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{
            __html: blogPost.replace(
              `<img loading="lazy" width="1024" height="512"`,
              `<img loading="lazy"`
            ),
          }}
        ></div>
        <div
          style={{
            display: "flex",
          }}
        >
          <hr style={{ width: "100vw" }} />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="blog-post--footer-content">
            <div>
              Published: {moment(date).format("Do MMMM YYYY")} <br />
              By: {author}
            </div>
            <div>Categorised as {category}</div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 20,
        }}
      >
        <div>
          {previous && (
            <div
              className="blog-nav-link"
              style={{ cursor: "pointer", padding: 20, textAlign: "left", marginRight: "3px" }}
              onClick={() => navigate(`/entry/${previous}`)}
              onKeyDown={() => null}
              tabIndex={0}
              role="button"
            >
              {"<< Previous Post"} <br />
              <strong>{previousTitle}</strong>
            </div>
          )}
        </div>

        <div>
          {next && (
            <div
              className="blog-nav-link"
              style={{ cursor: "pointer", padding: 20, textAlign: "right", marginLeft: "3px" }}
              onClick={() => navigate(`/entry/${next}`)}
              onKeyDown={() => null}
              tabIndex={0}
              role="button"
            >
              {"Next Post >>"}
              <br />
              <strong>{nextTitle}</strong>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost